import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["visaStateField", "visaStateElement", "visaIdNumberField", "visaIdNumberContainer", "select"];
    static euMembersValue = { euMembers: Array };

    connect() {
        // Initial toggle on page load
        // Initial toggle on page load
        this.toggleRequired();

        // Add event listener to visaStateField
        this.visaStateFieldTarget.addEventListener('change', () => this.toggleVisaIdNumberRequired());
    }

    toggleVisaStateRequired() {
        const selectedNationality = this.selectTarget.value;
        // Get the list of EU members from the data attribute
        this.euMembersValue = this.selectTarget.dataset.values;
        const isEU = this.euMembersValue.includes(selectedNationality);
        this.visaStateFieldTarget.required = !isEU;
        if (this.VisaIdNumberFieldTarget) {
          this.visaIdNumberFieldTarget.required = !isEU;
        }

        // hide the visaStateField
        if (isEU) {
            this.visaStateElementTarget.classList.add("hidden");
        } else
        {
          this.visaStateElementTarget.classList.remove("hidden");
        }
    }
    toggleVisaIdNumberRequired() {
        if (this.visaIdNumberFieldTarget) {
            const requireVisaId = this.visaStateFieldTarget.value !== '' &&
                !["nein | keine", "ja | studentenvisum", "ja | asylberechtigt mit konventionsreisepass"].includes(this.visaStateFieldTarget.value.toLowerCase());
            this.visaIdNumberFieldTarget.required = requireVisaId;

            // Show or hide the visaIdNumberField based on the visaStateField value
            if (requireVisaId) {
                this.visaIdNumberContainerTarget.classList.remove("hidden");
                // the target is the div around it - get the input inside the visaIdNumberFieldTarget and set it to required
                this.visaIdNumberFieldTarget.required = true;
            } else {
                this.visaIdNumberContainerTarget.classList.add("hidden");
                this.visaIdNumberFieldTarget.required = false;
            }
        }
    }

    // Custom action to toggle the required attribute when needed
    toggleRequired() {
        this.toggleVisaStateRequired();
        this.toggleVisaIdNumberRequired();
    }
}